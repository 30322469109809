@import "../abstracts/variables";

//ANIMATIONS
@mixin transform($transforms...) {
  $transform-list: join($transforms, null, space);
  transform: $transform-list;
}

@mixin keyframe($animation-name) {
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($delay, $duration, $iterations, $animation) {
  animation-delay: $delay;
  animation-duration: $duration;
  animation-iteration-count: $iterations;
  animation-name: $animation;
}

//MEDIA QUERIES
@mixin tablet-screens {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin desktop-screens {
  @media (min-width: 1200px) {
    @content;
  }
}

//Pseudo element
@mixin pseudo(
  $loc: after,
  $content: "",
  $top: 0,
  $bottom: 0,
  $left: 0,
  $right: 0
) {
  position: relative;

  &::#{$loc} {
    content: $content;
    position: absolute;
    top: $top;
    bottom: $bottom;
    left: $left;
    right: $right;
    @content;
  }
}

//Button
@mixin link-btn($bg: $contrast-orange, $font: $sass-subhead-font, $font-color: $text-base-white, $padding: 1.5rem 3rem) {
    background: $bg;
    font-family: $font;
    color: $font-color;
    padding: $padding;
    transition: all 0.2s;
    cursor: pointer;
    @content;

    &:hover {
      background: $font-color;
      color: $bg;
    }
  
}