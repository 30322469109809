@import "../abstracts/variables";

.navbar {
  background: $bg-dark-gray;
  display: grid;
  position: relative;
  min-height: 5rem;
  padding-right: 1rem;
}



.navbar-list {
  display: grid;
  grid-template-columns: 1fr max-content;
  list-style: none;

}

.navbar-title {
  padding: 0;
  margin: 0;
  justify-self: center;
  align-self: center;
  color: $text-header-white;
  text-transform: uppercase;
  font-size: 2.5em;
  letter-spacing: -0.04em;
}

.navbar-list_item {
  justify-self: end;
  align-self: center;
}
