@import "../abstracts/variables";
@import "../abstracts/mixins";

.high-contrast-btn {
  @include link-btn($padding: 0.75rem 2.5rem) {
    display: inline-block;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: bold;
    font-size: 1.2em;
  }
}

.high-contrast-btn-sm {
  @include link-btn($padding: 0.25rem 0.75rem) {
    display: inline-block;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    font-weight: bold;
    font-size: 0.7em;
    max-height: 1.5rem;
  }
}

.btn {
  background: $sass-graphite;
  font-family: $sass-subhead-font;
  color: white;
  padding: 1rem 0.6rem;
  border-radius: 5%;
  border: none;
  cursor: pointer;
  &:hover {
    background: $sass-light-gray;
    color: $sass-graphite;
    cursor: pointer;
  }
  p {
    line-height: 0;
    padding: 0;
    margin: 0;
    font-weight: bold;
  }
}

.exit-btn {
  background: rgba(255, 255, 255, 0);
  color: $sass-light-gray;
  border: none;
  transition: transform 0.3s;
  cursor: pointer;
  &:hover {
    cursor: pointer;
    transform: scale(1.6);
  }
}
