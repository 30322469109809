@import "../abstracts/variables";
@import "../abstracts/mixins";
@import "../components/contrast";

.image-container {
  display: grid;
  margin-top: 1rem;
  padding-bottom: 2rem;
  align-items: center;
  justify-content: center;
  justify-items: center;
  column-gap: 1rem;
  row-gap: 1rem;
  grid-template-columns: repeat(auto-fit, 250px);
  @include tablet-screens {
    justify-content: center;
    justify-items: center;
    column-gap: 0.8rem;
    row-gap: 0.8rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

.dashboard-header {
  display: grid;
  justify-content: start;
  justify-items: center;
  margin-top: 5rem;
  margin-left: 2rem;
  min-height: 75%;
}

.dashboard-header_text {
  font-size: clamp(1em, 1rem + 2vw, 3.5em);
  color: $text-header-black;
  max-width: 16ch;
  text-transform: uppercase;
  letter-spacing: -0.03em;
}

.dashboard-container {
  display: grid;
  padding-bottom: 2rem;
  .btn {
    justify-self: center;
    align-self: center;
  }
}
