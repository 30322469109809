.modal-background {
  position: fixed;
  display: grid;
  min-height: 100vh;
  width: 100vw;
  margin: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.95);
  z-index: 1;
  cursor: pointer;
}

.image-modal {
  display: grid;
  justify-self: center;
  align-self: center;
  max-height: 90vh;
  height: 80%;
  max-width: 90vw;
  width: 80%;
  z-index: 2;
  cursor: pointer;

  video {
    position: relative;
    max-height: 85vh;
    height: 100%;
    max-width: 95vw;
    width: 100%;
  }

  img {
    max-width: 100vw;
    max-height: 100vh;
    height: auto;
    margin: auto;
    border-radius: 0.2%;
    color: white;
    @include desktop-screens {
      max-width: 100%;
      max-height: 80vh;
      width: auto;
    }
    @include tablet-screens {
      max-width: 100%;
      max-height: 75vh;
      width: auto;
    }
  }
}
