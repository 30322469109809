@import "../abstracts/variables";
@import "../abstracts/mixins";

.loading {
  font-family: $sass-subhead-font;
  font-size: 3rem;
  color: $sass-dark-salmon;
  align-self: start;
  line-height: 0;
}

.loading-container {
  display: grid;
  grid-template-columns: repeat(3, 0.5rem);
  grid-gap: 0.1rem;
  justify-content: center;
  align-items: start;
  height: 2rem;
}

.loading-anim_1 {
  @include animation(0s, 0.8s, infinite, bounce);
  @include keyframe(bounce) {
    50% {
      transform: translate(0, 2px);
    }

    100% {
      transform: translate(0, 0);
    }
  }
}
.loading-anim_2 {
  @include animation(0.2s, 0.8s, infinite, bounce);
  @include keyframe(bounce) {
    50% {
      transform: translate(0, 2px);
    }

    100% {
      transform: translate(0, 0);
    }
  }
}
.loading-anim_3 {
  @include animation(0.4s, 0.8s, infinite, bounce);
  @include keyframe(bounce) {
    50% {
      transform: translate(0, 2px);
    }

    100% {
      transform: translate(0, 0);
    }
  }
}
