@import "../abstracts/variables";

.welcome {
  display: grid;
  grid-template-rows: repeat(3, min-content);
  grid-gap: 1rem;
  justify-content: center;
  align-content: end;
  margin-top: 7rem;
  margin-left: 0.8rem;
}

.welcome-header_text {
  margin: 0;
  font-size: clamp(2em, 1rem + 7vw, 5em);
  color: $text-header-black;
  max-width: 16ch;
  text-transform: uppercase;
  letter-spacing: -0.03em;
}

.welcome-subhead {
  margin: 0;
  max-width: 27ch;
  font-size: clamp(0.5em, 1rem + 0.5vw, 2.5em);
  font-weight: bolder;
  color: $text-base-black;
  text-transform: uppercase;
  letter-spacing: -0.03em;
}

.welcome-description_text {
  margin: 0;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
  max-width: 30ch;
  font-family: $sass-text-font;
  font-size: clamp(0.2em, 1rem + 0.5vw, 1.5em);
  font-weight: normal;
  color: $text-base-black;
}
