@import "../abstracts/variables";

body, html {
  background: $bg-white;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1,
h2,
h3,
p {
  padding: 0;
  margin: 0;
}

h1
 {
  font-family: $sass-header-font;
  line-height: 1;
}

h2,
h3 {
  font-family: $sass-subhead-font;
  line-height: 1;
}

p {
  font-family: $sass-text-font;
  line-height: 1.6;
}
